import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../utils';
import { Link } from 'react-router-dom';

import { listAllProducts } from '../redux/actions/productActions';
import Loader from './Loader';
import Message from './Message';
import { formatImageUrl } from '../utils';
import { isLocalhost } from '../utils';

const Recommended = ({ match }) => {
  const dispatch = useDispatch();
  const productListAll = useSelector((state) => state.productListAll);
  const { loading, error, products } = productListAll;

  // const [cart, setCart] = useState([]);
  // const [cartTotal, setCartTotal] = useState([]);
  const [inQueue, setInQueue] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [emptyList, setEmptyList] = useState([]);

  // $scope.emptyCart = () => {
  //   $scope.cart = [];
  //   $scope.cartTotal = 0;
  //   $scope.inQueue = [0, 0, 0, 0, 0, 0, 0];
  //   $scope.evaluate(null);
  // };

  const evaluate = async (x) => {
    console.log('ITEM INDEX', x);
    const data = fillArrayWithProducts();
    const inQueueClone = inQueue || emptyList;
    // console.log('emptyList', emptyList);
    console.log('empty data', data);
    if (x !== null) {
      data[x] = 1;
      inQueueClone[x] = 1;
      setInQueue(inQueueClone);
    }
    console.log('with 1 x data', data);

    const query = { q: inQueueClone };

    const response = await axios({
      url: `${BASE_URL}/api/ai/evaluate_model`,
      method: 'POST',
      data: query,
    });

    // console.log(query.q);
    // console.log(response.data);
    setAnswers(response.data);

    const new_training_data = {
      q: {
        cart: inQueueClone,
        newItem: data,
        key: 'recommended',
      },
    };
    console.log('done data', data);
    console.log('new_training_data.q');
    console.log(new_training_data.q);

    const _resp = await axios({
      url: `${BASE_URL}/api/ai/upload_training_data`,
      method: 'POST',
      data: new_training_data,
    });

    console.log(_resp);
  };

  useEffect(() => {
    dispatch(listAllProducts());
  }, [dispatch]);

  const fillArrayWithProducts = () => new Array(products.length).fill(0);
  useEffect(() => {
    try {
      if (products.length) {
        !emptyList.length && setEmptyList(new Array(products.length).fill(0));

        const init = async () => {
          const query = { q: fillArrayWithProducts() };
          const response = await axios({
            url: `${BASE_URL}/api/ai/evaluate_model`,
            method: 'POST',
            data: query,
          });
          setAnswers(response.data);
        };

        init();
      }
    } catch (error) {
      console.log(error);
    }

    return () => {};
  }, [dispatch, products, emptyList]);

  return (
    // (answers.length || !products.length) &&
    loading ? (
      <Loader></Loader>
    ) : error ? (
      <Message variant='danger'>{error}</Message>
    ) : (
      <div>
        <div className='background'>
          <div className='container'>
            <div className='le_spacer'></div>

            <div className='row'>
              <div className='row'>
                {answers.map((product, index) => {
                  return (
                    <div key={index} className='col-md-4 categoryContainer'>
                      <Card className='my-3 rounded product_card_container'>
                        <Link
                          to={`/product/${product._id}`}
                          onClick={() => evaluate(product.index)}
                        >
                          <Image
                            src={formatImageUrl(product)}
                            alt={product.name}
                            fluid
                            rounded
                          ></Image>
                        </Link>
                        <Card.Body>
                          <Link
                            to={`/product/${product._id}`}
                            onClick={() => evaluate(product.index)}
                          >
                            <Card.Title as='div'>
                              <strong>{product.name}</strong>
                            </Card.Title>
                          </Link>
                          <Card.Text as='div'></Card.Text>
                          <Card.Text as='h3'>
                            <div className='my-0'>${product.price}</div>
                            <br></br>

                            {isLocalhost && <p>Score: {product.score}</p>}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Recommended;
