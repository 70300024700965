import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  ListGroupItem,
  Form,
} from 'react-bootstrap';
import Rating from '../components/Rating';

import Loader from '../components/Loader';
import Message from '../components/Message';
import {
  listProductDetails,
  createProductReview,
} from '../redux/actions/productActions';
import { formatImageUrl } from './../utils';
import { PRODUCT_CREATE_REVIEW_RESET } from '../redux/constants/productConstants';
import Meta from '../components/Meta';
import Recommended from '../components/Recommended';
import { BASE_URL } from '../utils';
import { isLocalhost } from './../utils';
const ProductScreen = ({ match, history }) => {
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { error: errorCreateReview, success: successCreateReview } =
    productReviewCreate;

  useEffect(() => {
    if (successCreateReview) {
      alert('Review Created');
      setRating(0);
      setComment('');
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(match.params.id));
  }, [dispatch, match, successCreateReview]);
  const addToCartHandler = (e) => {
    history.push(`/cart/${match.params.id}?qty=${quantity}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProductReview(match.params.id, { rating, comment }));
  };

  const trainRecommendedItems = async () => {
    const response = await axios({
      url: `${BASE_URL}/api/ai/train_model`,
      method: 'POST',
    });
    console.log(response);
    console.log(response.data);
  };
  return (
    <div>
      <Link className='btn btn-light my-3' to='/'>
        GO BACK
      </Link>

      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={product.name}></Meta>
          <Row>
            <Col md={6}>
              <Image
                src={formatImageUrl(product)}
                alt={product.name}
                fluid
              ></Image>
              <Row>
                <Col md={12}>
                  <h2>Reviews</h2>
                  {product.reviews.length === 0 && (
                    <Message>No Reviews</Message>
                  )}
                  <ListGroup varian='flush'>
                    {product.reviews.map((review) => (
                      <ListGroupItem key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating}> </Rating>
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                      </ListGroupItem>
                    ))}
                    <ListGroupItem>
                      <h2>Write a Customer Review</h2>

                      {errorCreateReview && (
                        <Message variant='danger'>{errorCreateReview}</Message>
                      )}
                      {userInfo ? (
                        <Form onSubmit={submitHandler}>
                          <Form.Group controlId='rating'>
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                              as='select'
                              value={rating}
                              onChange={(e) => {
                                setRating(e.target.value);
                              }}
                            >
                              <option value=''>Select...</option>
                              <option value='1'>1 - Poor</option>
                              <option value='2'>2 - Fair</option>
                              <option value='3'>3 - Good</option>
                              <option value='4'>4 - Very Good</option>
                              <option value='5'>5 - Excellent</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId='comment'>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as='textarea'
                              row='3'
                              value={comment}
                              onChange={(e) => {
                                setComment(e.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>

                          <Button
                            type='submit'
                            variant='primary'
                            className='lucid_button keikei_button'
                          >
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <Message>
                          Please <Link to='/login'>sign in</Link> to write a
                          review.
                        </Message>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6} xs={12}>
                  <ListGroup variant='flush'>
                    <ListGroupItem>
                      <h2>{product.name}</h2>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                      ></Rating>
                    </ListGroupItem>
                    <ListGroupItem>Price: ${product.price}</ListGroupItem>
                    <ListGroupItem>
                      Description: {product.description}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col md={6} xs={12}>
                  <Card className='product_screen_card'>
                    <ListGroup variant='flush'>
                      <ListGroupItem>
                        <Row>
                          <Col>Price:</Col>
                          <Col>
                            <strong>${product.price}</strong>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem>
                        <Row>
                          <Col>Status:</Col>
                          <Col>
                            <strong>
                              {product.countInStock > 0
                                ? 'In Stock'
                                : 'Out of Stock'}
                            </strong>
                          </Col>
                        </Row>
                      </ListGroupItem>

                      {product.countInStock > 0 && (
                        <ListGroupItem>
                          <Row>
                            <Col>Qty:</Col>
                            <Col>
                              <Form.Control
                                as='select'
                                value={quantity}
                                onChange={(e) => {
                                  setQuantity(e.target.value);
                                }}
                              >
                                {[...Array(product.countInStock).keys()].map(
                                  (x) => {
                                    return (
                                      <option key={x + 1} value={x + 1}>
                                        {x + 1}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}
                      <ListGroup.Item>
                        <Button
                          type='button'
                          className='btn-block w-100 keikei_button'
                          disabled={product.countInStock === 0}
                          onClick={addToCartHandler}
                        >
                          ADD TO CART
                        </Button>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={12}>
                  <ListGroup variant='flush'>
                    <ListGroupItem>
                      <div
                        className='col-md-6'
                        style={{ display: 'inline-flex' }}
                      >
                        <h2>Suggested Items:</h2>
                      </div>
                      {isLocalhost && (
                        <div
                          className='col-md-6'
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'end',
                          }}
                        >
                          <Button onClick={trainRecommendedItems}>TRAIN</Button>
                        </div>
                      )}
                    </ListGroupItem>

                    <ListGroupItem>
                      <Recommended />
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProductScreen;
