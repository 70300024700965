import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Welcome to Lucid Wax',
  keywords:
    'scented candles, best scented candles, best smelling candles, good smelling candles, winter candles, spring candles, relaxing candles, votive candles',
  description:
    'Fill any room or space in your home with fresh fragrances and d&eacute;cor-friendly style.',
};
export default Meta;
